<template>
    <aside class="page-aside">
        <nav class="snb">
            <ol class="snb-list">
                <li v-for="(nav, index) in navs" class="snb-item" @click="clickNav(index)" :class="{'is-active': activeIndex === index}" :key="index">
                    <a href="javascript:" class="snb-link">{{ nav }}</a>
                </li>
            </ol>
        </nav>
    </aside>
</template>
<script>
export default {
    name: 'IntroNav',
    props: {
        navs: Array,
        activeIndex: Number,
    },
    emits: ['clickNav'],
    setup(props, {emit}) {
        const clickNav = index => {
            emit('clickNav', index);
        };

        return {
            clickNav,
        };
    },
};
</script>
