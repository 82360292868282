<template>
    <main class="swcluster-main" id="swcluster-biz">
        <!-- page-banner -->
        <div class="page-banner">
            <div class="banner-component">
                <CommonBreadcrumb />
            </div>
            <div class="banner-image">
                <img src="@/assets/images/biz/main_banner_01.png" alt="" />
            </div>
        </div>
        <!-- page-header -->
        <div class="page-header sw-divider">
            <div class="header-component"></div>
        </div>
        <!-- page-body -->
        <div class="page-body page-component">
            <!-- page-aside -->
            <IntroNav :active-index="activeIdx" :navs="navs" @clickNav="goToEl" />
            <!-- page-contents -->

            <div class="page-contents">
                <!-- content-section:사업개요 -->
                <article :ref="el => els.push(el)" class="content-section">
                    <header class="section-header">
                        <h3 class="title">사업개요</h3>
                        <p class="description">
                            대구 제주 SW융합 클러스터 2.0 사업은 대구와 제주의 ITSW기업에게 SW제품개발에 필요한 데이터를 제공하고, 비즈니스 인사이트를 발굴할 수
                            있는 기초 분석환경을 제공하고, 인공지능 알고리즘과 모델 개발을 위한 최신의 고성능 환경을 지원하는 사업입니다. <br />본 사업은 제주
                            거점에서는 소프트웨어 융합 서비스 실증·상용화를, 대구 거점에서는 소프트웨어 융합 서비스 개발을 주로 담당합니다.
                        </p>
                        <p class="description">
                            <br />제주와 대구가 공동 추진하는 이번 사업을 통해 지역별 소프트웨어 산업 생태계의 근간을 마련하고, 이를 바탕으로 지역 디지털전환 및
                            신사업 발굴의 동력이 갖춰지기를 기대하고 있습니다.
                        </p>
                    </header>
                </article>
                <!-- content-section:대구・제주 데이터 허브포털 -->
                <article class="content-section section-vision section-divider">
                    <header class="section-header">
                        <h3 class="title">대구・제주 데이터 허브포털</h3>
                    </header>
                    <!-- chart-container -->
                    <div class="chart-container">
                        <img src="@/assets/images/biz/hub_table.svg" />
                    </div>
                </article>

                <!-- content-section:비전 및 전략 -->
                <article :ref="el => els.push(el)" class="content-section section-vision section-divider">
                    <header class="section-header">
                        <h3 class="title">비전 및 전략</h3>
                        <p class="description">데이터 중심의 SW융합 신산업 육성을 선도할 데이터 허브 플랫폼 구축</p>
                    </header>
                    <!-- chart-container -->
                    <div class="chart-container vision-chart-container">
                        <!-- chart-group -->
                        <div class="chart-group group-01">
                            <h4 class="group-title">2022 <sup>1차</sup></h4>
                            <ol class="chart-table">
                                <li class="table-column column-01">
                                    <h5 class="column-title">원천데이터</h5>
                                    <div class="item"><h6 class="item-title">대구광역시 AI도시 데이터</h6></div>
                                    <div class="item"><h6 class="item-title">제주특별자치도 AI 도시 데이터</h6></div>
                                    <div class="item"><h6 class="item-title">공공기관 AI 도시 데이터</h6></div>
                                    <div class="item"><h6 class="item-title">민간기업 AI 도시 데이터</h6></div>
                                </li>
                                <li class="table-column column-02">
                                    <h5 class="column-title">데이터 저장 ・ 분류 ・ 가공</h5>
                                    <div class="item">
                                        <h5 class="item-title">데이터 레이크 Data Lake</h5>
                                        <p class="item-text">대구-제주 도시데이터 수집 ・ 처리 ・ 적재 ・ 표준화 ・ 검증</p>
                                    </div>
                                    <div class="item">
                                        <h5 class="item-title">데이터 마트 Data Mart</h5>
                                        <p class="item-text">4개 분야별 신서비스 개발을 위한 데이터 마트 구성</p>
                                    </div>
                                    <div class="item">
                                        <h5 class="item-title">데이터 허브 Data Hub</h5>
                                        <p class="item-text">데이터 소개 ・ 다운로드 ・ 시각화분석 사례</p>
                                    </div>
                                    <div class="between-item"><span class="item-text">클라우드연동</span></div>
                                </li>
                                <li class="table-column column-03">
                                    <h5 class="column-title">데이터 분석 ・ 제공</h5>
                                    <div class="item">
                                        <h5 class="item-title">시각화 분석 서비스</h5>
                                        <p class="item-text">시각화 분석 툴 연동 및 기능구현</p>
                                    </div>
                                    <div class="item">
                                        <h5 class="item-title">통계 분석 서비스</h5>
                                        <p class="item-text">DB Query 서비스 (DB-less)</p>
                                    </div>
                                    <div class="item">
                                        <h5 class="item-title">AI & ML 분석 서비스</h5>
                                        <p class="item-text">사용자별 샌드박스(폐쇄망) 제공 <br />고성능 ・ 자동화 개발환경 제공</p>
                                    </div>
                                </li>
                            </ol>
                        </div>
                        <!-- chart-group -->
                        <div class="chart-group group-02">
                            <h4 class="group-title">2023 <sup>2차</sup></h4>
                            <ol class="chart-table">
                                <li class="table-column">
                                    <div class="item">
                                        <h6 class="item-title">데이터 소싱 확대</h6>
                                    </div>
                                </li>
                                <li class="table-column">
                                    <div class="item">
                                        <h6 class="item-title">외부 데이터 연동 확대</h6>
                                    </div>
                                </li>
                                <li class="table-column">
                                    <div class="item">
                                        <h6 class="item-title">추가 분석 서비스 지원 확대</h6>
                                        <p class="item-text">시민 사용자 대상 서비스 포함</p>
                                    </div>
                                </li>
                            </ol>
                        </div>
                        <!-- chart-group -->
                        <div class="chart-group">
                            <h4 class="group-title">2024 <sup>3차</sup></h4>
                            <ol class="chart-table">
                                <li class="table-column">
                                    <div class="item">
                                        <h6 class="item-title">데이터 소싱 추가 확대</h6>
                                    </div>
                                </li>
                                <li class="table-column">
                                    <div class="item">
                                        <h6 class="item-title">데이터 자동화 처리</h6>
                                    </div>
                                </li>
                                <li class="table-column">
                                    <div class="item">
                                        <h6 class="item-title">클라우드 기능 모듈 구현</h6>
                                        <p class="item-text">On-Promise 이전 대상</p>
                                    </div>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <!-- //chart-container -->
                </article>
                <!-- content-section:조직도 -->
                <article :ref="el => els.push(el)" class="content-section section-organization section-divider">
                    <header class="section-header">
                        <h3 class="title">조직도</h3>
                    </header>
                    <!-- chart-container -->
                    <div class="chart-container organization-chart-container">
                        <h4 class="chart-title">대구-제주 SW 융합 클러스터 추진단</h4>
                        <!-- chart-table -->
                        <ol class="chart-table table-01">
                            <!-- table-column -->
                            <li class="table-column column-01">
                                <h5 class="title">대구거점 <span class="title-sub">17명</span></h5>
                                <div class="item">
                                    <div class="item-top">
                                        <img src="@/assets/images/biz/logo_1.svg" alt="" class="logo" />
                                        <strong class="top-text">(재)대구디지털혁신진흥원</strong>
                                    </div>
                                    <div class="item-body">
                                        <h6 class="item-title">SW융합서비스 <br />개발 및 상용화</h6>
                                        <ul class="sub-item-list">
                                            <li class="sub-item"><span class="sub-item-text">DDIC 기반 서비스 개발 ・ 상용화</span></li>
                                            <li class="sub-item"><span class="sub-item-text">데이터 허브 플랫폼 구축 및 운영</span></li>
                                            <li class="sub-item"><span class="sub-item-text">투자 자금 조성 및 투자 지원</span></li>
                                            <li class="sub-item"><span class="sub-item-text">DDIC 산업 현황 및 실태조사</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <!-- table-column -->
                            <li class="table-column column-02">
                                <h5 class="title">협력 인프라 <span class="title-sub">12명</span></h5>
                                <div class="item-list">
                                    <div class="item">
                                        <div class="item-top">
                                            <img src="@/assets/images/biz/logo_2.svg" alt="" class="logo" />
                                            <strong class="top-text">(재)대구테크노파크</strong>
                                        </div>
                                        <div class="item-body">
                                            <h6 class="item-title">대구 리빙랩 <br />운영</h6>
                                            <ul class="sub-item-list">
                                                <li class="sub-item"><span class="sub-item-text">DDIC 기반 서비스 개발 ・ 상용화</span></li>
                                                <li class="sub-item"><span class="sub-item-text">도시 현안 문제 도출</span></li>
                                                <li class="sub-item"><span class="sub-item-text">SW융합 서비스 제주 실증 지원</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="item-top">
                                            <img src="@/assets/images/biz/logo_3.png" alt="" class="logo" />
                                            <strong class="top-text">한국정보통신기술협회</strong>
                                        </div>
                                        <div class="item-body">
                                            <h6 class="item-title">데이터 품질 <br />혁신 지원</h6>
                                            <ul class="sub-item-list">
                                                <li class="sub-item"><span class="sub-item-text">도시 데이터 품질 컨설팅 및 검증</span></li>
                                                <li class="sub-item"><span class="sub-item-text">DDIC 신서비스 데이터 표준화</span></li>
                                                <li class="sub-item"><span class="sub-item-text">도시서비스 품질 평가모델 개발</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <!-- table-column -->
                            <li class="table-column column-03">
                                <h5 class="title">제주거점 <span class="title-sub">16명</span></h5>
                                <div class="item">
                                    <div class="item-top">
                                        <img src="@/assets/images/biz/logo_4.png" alt="" class="logo" />
                                        <strong class="top-text">(재)제주테크노파크</strong>
                                    </div>
                                    <div class="item-body">
                                        <h6 class="item-title">SW융합 서비스 <br />실증 ・ 상용화</h6>
                                        <ul class="sub-item-list">
                                            <li class="sub-item"><span class="sub-item-text">DDIC 기반 서비스 실증 ・ 상용화</span></li>
                                            <li class="sub-item">
                                                <span class="sub-item-text">스타트업 혁신 아이디어 <br />사업화 원스톱 지원</span>
                                            </li>
                                            <li class="sub-item"><span class="sub-item-text">데이터 허브 플랫폼 구축 및 운영</span></li>
                                            <li class="sub-item"><span class="sub-item-text">제주 리빙랩 운영</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ol>
                        <!-- chart-table -->
                        <div class="chart-table table-02">
                            <!-- table-column -->
                            <div class="table-column column-01">
                                <div class="item"><span class="item-title">SW융합서비스 상용화 지원</span></div>
                                <div class="item"><span class="item-title">벤처기업 육석 및 투자환경 개선</span></div>
                                <div class="item"><span class="item-title">공동 데이터 허브 운영</span></div>
                                <div class="item"><span class="item-title">글로벌 진출 지원</span></div>
                            </div>
                            <!-- table-column -->
                            <div class="table-column column-02">
                                <div class="item"><span class="item-title">리빙랩 운영 및 실증지원</span></div>
                                <div class="item"><span class="item-title">네트워킹 지원</span></div>
                                <div class="item"><span class="item-title">규제 개선 지원</span></div>
                                <div class="item"><span class="item-title">SW혁신 인재양성</span></div>
                            </div>
                        </div>
                        <!-- chart-table -->
                        <div class="chart-table table-03">
                            <!-- table-column -->
                            <ol class="table-column column-01">
                                <li class="item">
                                    <strong class="item-title">DB.B 연구회</strong>
                                    <p class="item-text">스마트시티 산업 <br />비즈니스 모델 도출</p>
                                </li>
                                <li class="item">
                                    <strong class="item-title">DDIC 혁신성장 거버넌스</strong>
                                    <p class="item-text">클러스터 활성화 지원, <br />신사업 발굴</p>
                                </li>
                                <li class="item">
                                    <strong class="item-title">도시 서비스 혁신 교류회</strong>
                                    <p class="item-text">성과확산, 기술교류, <br />네트워킹 지원</p>
                                </li>
                                <li class="item">
                                    <strong class="item-title">규제 개선 위원회</strong>
                                    <p class="item-text">SW융합서비스 규제 <br />애로사항 발굴</p>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <!-- //chart-container -->
                </article>
                <!-- content-section:위치 -->
                <article :ref="el => els.push(el)" class="content-section section-location section-divider">
                    <header class="section-header">
                        <h3 class="title">위치</h3>
                    </header>
                    <div class="location-container">
                        <div class="location-item">
                            <div class="location-map">
                                <a href="https://naver.me/xxxYWdrT" target="_blank">
                                    <i class="icon-pin"><img src="@/assets/images/biz/ic_map_pin_daegu.svg" alt="" /></i>
                                    <div id="map">
                                        <img src="@/assets/images/biz/img_map.jpg" alt="" />
                                    </div>
                                </a>
                            </div>
                            <div class="location-content">
                                <p class="content-text">대구광역시 수성구 알파시티1로 170</p>
                                <ul class="content-item">
                                    <li class="item"><span class="item-text text-muted">TEL</span><span class="item-text">053)655-6891</span></li>
                                    <li class="item"><span class="item-text text-muted">FAX</span><span class="item-text">053)655-5635</span></li>
                                </ul>
                            </div>
                        </div>
                        <div class="location-item">
                            <div class="location-map">
                                <a href="https://naver.me/xUSu1ip4" target="_blank">
                                    <i class="icon-pin"><img src="@/assets/images/biz/ic_map_pin_jeju.svg" alt="" /></i>
                                    <div id="map2">
                                        <img src="@/assets/images/biz/img_map_jeju.jpg" alt="" />
                                    </div>
                                </a>
                            </div>
                            <div class="location-content">
                                <p class="content-text">제주특별자치도 제주시 중앙로 217</p>
                                <ul class="content-item">
                                    <li class="item"><span class="item-text text-muted">TEL</span><span class="item-text">064)720-2300</span></li>
                                    <li class="item"><span class="item-text text-muted">FAX</span><span class="item-text">064)751-3443</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </div>
        <!-- //page-body -->
    </main>
</template>

<script>
import IntroNav from '@/components/IntroNav';
import {onMounted, onUnmounted, ref} from 'vue';
import {tryCallback} from '@/assets/js/common.utils';
import CommonBreadcrumb from '@/components/common/layout/CommonBreadcrumb';

export default {
    name: 'Intro',
    components: {CommonBreadcrumb, IntroNav},
    setup() {
        const activeIdx = ref(-1);
        const navs = ['사업개요', '비전 및 전략', '조직도', '찾아오시는 길'];

        const els = ref([]);
        const elPositions = ref([]);
        const rootDiv = ref(null);

        // const posCorrection = computed(() => {
        //     // return 50;
        //     return 300;
        // });

        const goToTarget = (targetEle, _duration, _adjust) => {
            if (!targetEle) return;
            // - Get current &amp; target positions
            const scrollEle = document.documentElement || window.scrollingElement,
                currentY = scrollEle.scrollTop,
                // targetY = targetEle.offsetTop + targetEle.offsetHeight + (_adjust || 0);
                targetY = window.pageYOffset + targetEle.getBoundingClientRect().top + (_adjust || 0);

            animateScrollTo(currentY, targetY, _duration);

            // - Animate and scroll to target position
            function animateScrollTo(_startY, _endY, _duration) {
                _duration = _duration ? _duration : 100;
                // _duration = 100;
                const unitY = (targetY - currentY) / _duration;
                const startTime = new Date().getTime();
                // const endTime = new Date().getTime() + _duration;
                const endTime = new Date().getTime() + 100;
                const scrollTo = function () {
                    let now = new Date().getTime();
                    let passed = now - startTime;
                    if (now < endTime) {
                        scrollEle.scrollTop = currentY + unitY * passed;
                        requestAnimationFrame(scrollTo);
                    } else {
                        scrollEle.scrollTop = targetY;
                    }
                };
                requestAnimationFrame(scrollTo);
            }
        };

        const goToEl = index => {
            if (els.value[index]) {
                goToTarget(els.value[index], 0, -100);
            }
        };

        const tryStack = ref(0);

        const checkZeroOffset = () => {
            let notZero = true;
            for (let i = 0; i < els.value.length; i++) {
                if (els.value[i].offsetTop === 0) {
                    notZero = false;
                    break;
                }
                // if (window.pageYOffset + els.value[i].getBoundingClientRect().top < 750) {
                //     notZero = false;
                //     break;
                // }
            }
            return notZero;
        };

        const setElPositions = () => {
            rootDiv.value = document.getElementById('swcluster');
            elPositions.value = els.value.map(x => window.pageYOffset + x.getBoundingClientRect().top);
        };

        const setActiveIndex = () => {
            const scrollY = window.scrollY;
            const standard = elPositions.value[0] - 100;
            if (window.innerHeight + window.scrollY + 200 >= rootDiv.value.offsetHeight) {
                activeIdx.value = elPositions.value.length - 1;
                return;
            }

            if (scrollY < standard) {
                activeIdx.value = -1;
            } else {
                for (let i = elPositions.value.length - 1; i >= 0; i--) {
                    // if (i === 0) {
                    //     console.log(elPositions.value[i]);
                    //     console.log(scrollY);
                    //     if (elPositions.value[i] < (scrollY)) {
                    //         console.log('??');
                    //         activeIdx.value = i;
                    //         break;
                    //     }
                    // } else {
                    if (elPositions.value[i] < scrollY + 200) {
                        activeIdx.value = i;
                        break;
                    }
                    // }
                }
            }
        };

        onMounted(() => {
            tryCallback(
                tryStack.value,
                checkZeroOffset,
                () => {
                    setElPositions();
                    document.addEventListener('scroll', setActiveIndex);
                    setActiveIndex();
                },
                10,
            );
        });

        onUnmounted(() => {
            activeIdx.value = -1;
            document.removeEventListener('scroll', setActiveIndex);
        });

        return {
            activeIdx,
            navs,
            els,
            goToEl,
        };
    },
};
</script>
